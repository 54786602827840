import Nav from '../components/Nav';
import SwitchLanguage from '../components/SwitchLanguage';
import { useTranslation } from 'react-i18next';

export default function Success(props) {
  const { t } = useTranslation();

  window.setTimeout(function () {
    window.location.href = '/';
  }, 2000);

  return (
    <div className="wrapper">
      <header>
        <Nav />
      </header>
      <main>
        <div className="lead__header">
          <h1 className="lead__header__title">{props.type}</h1>
          <p className="lead__header__description">{props.description}</p>
        </div>

        <div className="success">
          <h3 className="lead__success">{props.sentence}</h3>
          <p className="lead__success--small">{t('redirecting-to-dash')}</p>
        </div>

        <SwitchLanguage />
      </main>
      <footer></footer>
    </div>
  );
}
