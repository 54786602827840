import Keycloak from "keycloak-js";
import { actions, send } from "xstate";
const { assign } = actions;

export const createKeycloakClient = assign({
  keycloak: (context, event) => {
    const kc = new Keycloak({
      url: context.url,
      realm: context.realm,
      clientId: context.clientId,
    });
    kc.onTokenExpired = () => {
      send({ type: "LOGOUT" });
    };

    return kc;
  },
});

export const redirectToLoginForm = (context, event) => {
  context.keycloak.login();
};

export const redirectToLogoutForm = (context, event) => {
  context.keycloak.logout();
};
export const saveUserProfile = assign({
  profile: (context, event) => event.data,
});

export const saveRemainingTime = assign({
  remainingTime: (context, event) => {
    console.log("event", event);
    const remainingTime = context.keycloak.tokenParsed.exp - Date.now() / 1000;

    return Math.floor(remainingTime);
  },
});

export const resetRemainingTime = assign({
  remainingTime: 0,
});
