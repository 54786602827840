import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/auth-context';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Loading from '../components/Loading';
import NotificationBox from './NotificationBox';

export default function FooterSingle(props) {
  const { t } = useTranslation();
  const id = props.id;
  const active = props.active;
  const { state } = useAuth();
  const keycloak = state.context?.keycloak;

  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState('');
  const [data, setData] = useState([]);
  const [openMessageText, setMessageText] = useState(
    'There is nothing for now'
  );
  const [loadingData, setLoadingData] = useState(true);

  async function getData() {
    await axios
      .get(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${id}${active === false ? '?active=0' : ''}`,
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        setData(response.data[0]['tasks']);
        setLoadingData(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    if (loadingData) {
      getData();
    }
  }, []);

  const columns = [
    {
      field: 'created_at',
      headerName: t('tasks-th-create-date'),
      type: 'date',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const date = new Date(params.row.created_at).toLocaleString('fr-FR');
        return <span className="listing__cell">{date}</span>;
      },
    },
    {
      field: 'ID',
      headerName: t('tasks-th-id'),
      width: '80',
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params) => `${id}`,
    },
    {
      field: 'caller_lastname',
      headerName: t('tasks-th-caller-lastname'),
      minWidth: '200',
      type: 'string',
      valueGetter: (params) => `${props.lastname}`,
    },
    {
      field: 'caller_firstname',
      headerName: t('tasks-th-caller-firstname'),
      minWidth: 200,
      type: 'string',
      valueGetter: (params) => `${props.firstname}`,
    },
    {
      field: 'type',
      headerName: t('tasks-th-type'),
      minWidth: 200,
      flex: 1,
      type: 'string',
      valueGetter: (params) => `${params.row.type['name']}`,
    },
    {
      field: 'expired_at',
      headerName: t('tasks-th-due-date'),
      type: 'date',
      minWidth: 200,
      flex: 1,
      valueGetter: (params) =>
        `${new Date(params.row.expired_at).toLocaleString('fr-FR')}`,
    },
    {
      field: 'completed_at',
      headerName: t('tasks-th-completed-date'),
      type: 'date',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        let date = t('currently-in-progress');

        if (params.row.completed_at !== null) {
          date = new Date(params.row.completed_at).toLocaleString('fr-FR');
        }
        return <span className="listing__cell">{date}</span>;
      },
    },
  ];

  function messageDataGrid() {
    if (loadingData === true) {
      return <Loading />;
    } else {
      return <p className="error__title">{t('empty-tasks-list')}</p>;
    }
  }

  let list = '';

  if (props.handle === 1) {
    return (
      <div className="single__footer">
        {openMessage && (
          <NotificationBox
            num="00000"
            desc={openMessageText}
            severity={severity}
          />
        )}
        <div className="single__history">
          <h2 className="lead__step__title lead__step__title--margin">
            {t('history')}
          </h2>

          <div className="tasks__grid">
            <Box sx={{ height: 500, width: '100%' }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[5]}
                disableColumnMenu
                getRowId={(row) => Math.random()}
                components={{
                  NoRowsOverlay: messageDataGrid,
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="single__footer">
        <div className="single__history">
          <h2 className="lead__step__title lead__step__title--margin">
            {t('history')}
          </h2>

          <div className="tasks__grid">
            <Box sx={{ height: 500, width: '100%' }}>
              <DataGrid
                rows={Array.from(data)}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[5]}
                disableColumnMenu
                getRowId={(row) => row.id}
                components={{
                  Toolbar: GridToolbar,
                  NoRowsOverlay: messageDataGrid,
                }}
              />
            </Box>
          </div>
        </div>
      </div>
    );
  }
}
