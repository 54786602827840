import React, { useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import Nav from '../components/Nav';
import SwitchLanguage from '../components/SwitchLanguage';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/auth-context';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import NotificationBox from './NotificationBox';

const LeadIncomingCaller = ({ nextStep, handleChange, values }) => {
  const { state } = useAuth();
  const keycloak = state.context?.keycloak;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => {
    setOpenModal(true);
    setOpenMessage(false);
  };
  const [personId, setPersonId] = useState();
  const [active, setActive] = useState(0);
  const [archivedModalOpen, setArchivedModalOpen] = useState(false);
  const [inactiveModalOpen, setInactiveModalOpen] = useState(false);
  const [sentence, setSentence] = useState('');
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState('');
  const [openMessageText, setMessageText] = useState('');
  const [openMessageFull, setMessageFull] = useState('');
  const [errorCode, setErrorCode] = useState();
  const [handleModalOpen, setHandleModalOpen] = useState(false);
  const [IsEntryOpen, setIsEntryOpen] = useState(false);
  const [viewable, setViewable] = useState(false);

  const sleep = (milliseconds = 500) =>
    new Promise((resolve) => setTimeout(resolve, milliseconds));

  const handleClose = () => {
    setOpenModal(false);
    setArchivedModalOpen(false);
    setHandleModalOpen(false);
    setInactiveModalOpen(false);
    setSentence('');
    setOpenMessage(false);
    setViewable('');
  };

  async function getActive() {
    const response = await axios.get(
      (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
        `leads/identify?resident-lastname=${values.resident_name}&resident-firstname=${values.resident_surname}&resident-age=${values.resident_age}`,
      {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      }
    );

    if (response.data.exists === true) {
      console.log(response.data.active);
      return response.data.active;
    }
  }

  function refreshData() {
    axios
      .get(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `leads/identify?resident-lastname=${values.resident_name}&resident-firstname=${values.resident_surname}&resident-age=${values.resident_age}`,
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      )
      .then((response) => {
        if (response.data.exists === true) {
          handleOpen();
          setPersonId(response.data.id);

          if (response.data.active === 1) {
            setActive(1);
          }

          if (response.data.active === 0 && response.data.locked === 0) {
            setIsEntryOpen(false);
          }

          if (response.data.active === 0 && response.data.locked === 1) {
            setIsEntryOpen(true);
          }

          // Check if the person is archived
          if (response.data.archived_at !== null) {
            // Display a message saying the person is archived
            setArchivedModalOpen(true);
          }

          // Check if the person is locked
          if (response.data.locked === 0 && response.data.active === 1) {
            // Redirect to an unlock page or display unlock button
            setHandleModalOpen(true);
          }

          if (response.data.locked === 1 && response.data.appropriation === 1) {
            // Redirect to an unlock page or display unlock button
            setSentence('Another person is handling this');
          }

          if (response.data.locked === 1 && response.data.appropriation === 0) {
            // Redirect to an unlock page or display unlock button
            setSentence("You're handling this");
          }

          // Check if the person is inactive
          if (response.data.viewable === true) {
            // Display a message saying the person is inactive
            setViewable(true);
          } else {
            setViewable(false);
          }

          if (
            response.data.active === 0 &&
            response.data.archived_at === null
          ) {
            // Display a message saying the person is inactive
            setInactiveModalOpen(true);
          }
        } else {
          nextStep();
        }
      })
      .catch(function (error) {
        setOpenMessage(false); // Add this line
        setSeverity('error');
        setOpenMessage(true);
        setMessageText(error.response.data.error_description);
        setErrorCode(error.response.data.error_code);
      });
  }

  let Continue = (e) => {
    if (
      values.name === '' ||
      values.surname === '' ||
      values.phone === '' ||
      values.email === '' ||
      values.language === '' ||
      values.resident_name === '' ||
      values.resident_surname === '' ||
      values.resident_age === '' ||
      values.resident_referer === ''
    ) {
      setOpenMessage(false); // Add this line
      setSeverity('error');
      setOpenMessage(true);
      setMessageText('required-fields');
    } else {
      refreshData();
    }
  };

  async function unarchive(id) {
    try {
      const response = await axios.post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${id}/unarchive`,
        JSON.stringify(),
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      );

      if (response.status === 200) {
        setOpenMessage(false); // Add this line
        setSeverity('success');
        setOpenMessage(true);
        setMessageText('This registration has been updated');
        await sleep(1000);
        await handleEntry(id);

        // setTimeout(async () => {
        //   await handleEntry(id);
        //   resolve();
        // }, 1000);
      }
    } catch (error) {
      setOpenMessage(false); // Add this line
      setSeverity('error');
      setOpenMessage(true);
      setMessageText(error.response.data.error_description);
      setErrorCode(error.response.data.error_code);

      if (
        error.response.data.errors?.length > 0 &&
        error.response.data.errors[0]?.msg
      ) {
        setMessageFull(error.response.data.errors[0].msg);
      } else {
        setMessageFull('');
      }
    }
  }

  async function handleEntry(rowID) {
    const residence = localStorage.getItem('selectedResidence');

    try {
      const response = await axios.post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${rowID}/handle`,
        {
          residence: residence || '',
        },
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      );

      if (response.status === 200) {
        getData();
        setSentence('Process completed');
        await sleep(1000);
        refreshData();
      }
    } catch (error) {
      if (error.response?.status <= 404) {
        setOpenMessage(false); // Add this line
        setSeverity('error');
        setOpenMessage(true);
        setMessageText(error.response.data.error_description);
        setErrorCode(error.response.data.error_code);
      }
    }
  }

  const [unarchiving, setUnarchiving] = useState(false);

  const handleUnarchive = async (personId) => {
    setUnarchiving(true);
    await sleep(3000);
    await unarchive(personId);

    setArchivedModalOpen(false);
    setSentence('Process completed');
    setUnarchiving(false);
    startRedirecting(personId, active);
  };

  const handleCloseWithDelay = () => {
    handleClose();
  };

  const [handling, setHandling] = useState(false);

  const handleEntryWithDelay = async (personId) => {
    setHandling(true);
    await sleep(4000);
    await handleEntry(personId);
    setHandling(false);
    setHandleModalOpen(false);
    setSentence('Process completed');
    startRedirecting(personId, active);
  };

  const [handlingInactive, setHandlingInactive] = useState(false);

  const handleInactiveEntryWithDelay = async (personId) => {
    setHandlingInactive(true);

    await sleep(4000);
    if (IsEntryOpen === false) {
      await handleEntry(personId);
    }
    await openEntry(personId);
    setHandlingInactive(false);
    setInactiveModalOpen(false);
    setSentence('Process completed');
    startRedirecting(personId, active);
  };

  async function openEntry(id) {
    try {
      const response = await axios.post(
        (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
          `registrations/${id}/open`,
        JSON.stringify(),
        {
          headers: {
            Authorization: 'Bearer ' + keycloak.token,
          },
        }
      );
      if (response.status === 200) {
        setOpenMessage(false); // Add this line
        setSeverity('success');
        setOpenMessage(true);
        setMessageText('This registration has been updated');
      }
    } catch (error) {
      setOpenMessage(false); // Add this line
      setSeverity('error');
      setOpenMessage(true);
      setMessageText(error.response.data.error_description);
      setErrorCode(error.response.data.error_code);

      if (
        error.response.data.errors?.length > 0 &&
        error.response.data.errors[0]?.msg
      ) {
        setMessageFull(error.response.data.errors[0].msg);
      } else {
        setMessageFull('');
      }
    }
  }

  const [redirecting, setRedirecting] = useState(false);
  const [counter, setCounter] = useState(5);

  const startRedirecting = async (personId) => {
    setRedirecting(true);

    // Call getActive() function here and store the result
    const finalActive = await getActive();

    const intervalId = setInterval(() => {
      setCounter((currentCounter) => {
        if (currentCounter === 0) {
          if (finalActive !== 0) {
            window.location.replace(`/post/${personId}`);
            clearInterval(intervalId);
          } else {
            window.location.replace(`/post/${personId}?active=0`);
            clearInterval(intervalId);
          }
          return currentCounter;
        } else {
          return currentCounter - 1;
        }
      });
    }, 1000);
  };

  return (
    <div className="wrapper">
      <header>
        <Nav />
      </header>

      <main className="lead">
        <div className="lead__header">
          <h1 className="lead__header__title">{t('lead-title')}</h1>
          <p className="lead__header__description">{t('lead-text')}</p>
        </div>

        {openMessage && !openModal && (
          <NotificationBox
            num={errorCode}
            desc={t(openMessageText)}
            severity={severity}
            full={openMessageFull}
          />
        )}

        {/* <Modal
          className="tasks__modal"
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="alert-modal">
            <h3>{t('already-exists-title')}</h3>
            <p className="alert-modal__p">{t('already-exists-text')}</p>
            <Link
              className="alert-modal__link"
              to={`/post/${personId}${active === 0 ? '?active=false' : ''}`}
            >
              <span className="link">{t('already-exists-link')}</span>
            </Link>
            <small className="alert-modal__small" onClick={handleClose}>
              {t('already-exists-close')}
            </small>
          </Box>
        </Modal> */}

        <Modal
          className="tasks__modal"
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="alert-modal">
            {openMessage && openModal && (
              <NotificationBox
                num={errorCode}
                desc={t(openMessageText)}
                severity={severity}
                full={openMessageFull}
              />
            )}
            <div>
              <h3>{t('Existing entry')}</h3>
              {archivedModalOpen && viewable && !redirecting ? (
                <span className="alert-modal__link__text alert-modal__link__text--red">
                  {
                    // lead-exists-is-viewable-and-archived
                    t('lead-exists-is-viewable-and-archived')
                  }
                </span>
              ) : (
                ''
              )}

              {archivedModalOpen && !viewable && !redirecting ? (
                <span className="alert-modal__link__text alert-modal__link__text--red">
                  {
                    // lead-exists-is-not-viewable-and-archived
                    t('lead-exists-is-not-viewable-and-archived')
                  }
                </span>
              ) : (
                ''
              )}

              {!archivedModalOpen &&
              !inactiveModalOpen &&
              !handleModalOpen &&
              viewable &&
              !redirecting ? (
                <>
                  <span className="alert-modal__link__text alert-modal__link__text--red">
                    {
                      // lead-exists-is-viewable-and-user-residence-in-charge
                      t('lead-exists-is-viewable-and-user-residence-in-charge')
                    }
                  </span>
                </>
              ) : (
                ''
              )}

              {!archivedModalOpen &&
              !inactiveModalOpen &&
              handleModalOpen &&
              viewable &&
              !redirecting ? (
                <>
                  <span className="alert-modal__link__text alert-modal__link__text--red">
                    {
                      // lead-exists-is-viewable-and-can-be-handled
                      t('lead-exists-is-viewable-and-can-be-handled')
                    }
                  </span>
                </>
              ) : (
                ''
              )}

              {!archivedModalOpen &&
              !inactiveModalOpen &&
              handleModalOpen &&
              !viewable &&
              !redirecting ? (
                <>
                  <span className="alert-modal__link__text alert-modal__link__text--red">
                    {
                      // lead-exists-is-not-viewable-and-appropriation-is-possible
                      t(
                        'lead-exists-is-not-viewable-and-appropriation-is-possible'
                      )
                    }
                  </span>
                </>
              ) : (
                ''
              )}

              {!inactiveModalOpen &&
                !archivedModalOpen &&
                !handleModalOpen &&
                !viewable &&
                !redirecting && (
                  <>
                    <span className="alert-modal__link__text alert-modal__link__text--red">
                      {
                        // lead-exists-is-not-viewable-and-already-handled
                        t('lead-exists-is-not-viewable-and-already-handled')
                      }
                    </span>
                  </>
                )}

              {inactiveModalOpen &&
              !archivedModalOpen &&
              viewable &&
              !redirecting ? (
                <>
                  <span className="alert-modal__link__text alert-modal__link__text--red">
                    {
                      // lead-exists-is-viewable-and-closed-and-can-be-handled
                      t('lead-exists-is-viewable-and-closed-and-can-be-handled')
                    }
                  </span>
                </>
              ) : (
                ''
              )}

              {inactiveModalOpen &&
              !archivedModalOpen &&
              !viewable &&
              !redirecting ? (
                <>
                  <span className="alert-modal__link__text alert-modal__link__text--red">
                    {
                      // lead-exists-is-not-viewable-and-closed-and-can-be-handled
                      t(
                        'lead-exists-is-not-viewable-and-closed-and-can-be-handled'
                      )
                    }
                  </span>
                </>
              ) : (
                ''
              )}
            </div>

            {!archivedModalOpen && !inactiveModalOpen && (
              <>
                {viewable && (
                  <Link
                    className="alert-modal__link"
                    to={`/post/${personId}${active === 0 ? '?active=0' : ''}`}
                    target="_blank"
                  >
                    <span className="link--external">
                      {t('already-exists-link')}
                    </span>
                  </Link>
                )}
                <span> </span>
              </>
            )}

            {handleModalOpen && (
              <>
                <div className="single__footer__element">
                  <p className="alert-modal__last">
                    {t('Would you like to')}{' '}
                    <span className="green">{t('take in charge?')}</span>
                  </p>
                  <div className="alert-modal__container">
                    <Button
                      className={`form__submit form__submit--open ${
                        handlingInactive ? 'handle--double' : ''
                      }`}
                      onClick={() => {
                        handleEntryWithDelay(personId);
                      }}
                    >
                      <span className="incharge__text">
                        {handling
                          ? t('Handle process is running...')
                          : t('Oui, je le prends en charge')}
                      </span>
                    </Button>
                    <Button
                      className="alert-modal__close-btn"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <span className="incharge__text">{t('I give up')}</span>
                    </Button>
                  </div>
                </div>
              </>
            )}
            {archivedModalOpen && !inactiveModalOpen && !handleModalOpen && (
              <>
                {viewable && (
                  <Link
                    className="alert-modal__link"
                    to={`/post/${personId}${active === 0 ? '?active=0' : ''}`}
                    target="_blank"
                  >
                    <span className="link--external">
                      {t('already-exists-link')}
                    </span>
                  </Link>
                )}
                <div className="single__footer__element">
                  <p className="alert-modal__last">
                    {t('Would you like to')}{' '}
                    <span className="green">
                      {t('unarchive it and take charge?')}
                    </span>
                  </p>
                  <div className="alert-modal__container">
                    <Button
                      className={`form__submit form__submit--archive ${
                        unarchiving ? 'handle--double' : ''
                      }`}
                      onClick={() => {
                        handleUnarchive(personId);
                      }}
                    >
                      <span className="incharge__text">
                        {unarchiving
                          ? t('Unarchiving this file...')
                          : t(
                              "Yes, I am unarchiving it and i'm taking in charge"
                            )}
                      </span>
                    </Button>
                    <Button
                      className="alert-modal__close-btn"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <span className="incharge__text">{t('I give up')}</span>
                    </Button>
                  </div>
                </div>
              </>
            )}
            {inactiveModalOpen && (
              <>
                {viewable && (
                  <Link
                    className="alert-modal__link"
                    to={`/post/${personId}${active === 0 ? '?active=0' : ''}`}
                    target="_blank"
                  >
                    <span className="link--external">
                      {t('already-exists-link')}
                    </span>
                  </Link>
                )}
                <div className="single__footer__element">
                  <p className="alert-modal__last">
                    {t('Would you like to')}{' '}
                    <span className="green">{t('take in charge?')}</span>
                  </p>
                  <div className="alert-modal__container">
                    <Button
                      className={`form__submit form__submit--open ${
                        handlingInactive ? 'handle--double' : ''
                      }`}
                      onClick={async () =>
                        handleInactiveEntryWithDelay(personId)
                      }
                    >
                      <span className="incharge__text">
                        {handlingInactive
                          ? t('Handling this inactive person...')
                          : t('Oui, je le prends en charge')}
                      </span>
                    </Button>

                    <Button
                      className="alert-modal__close-btn"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <span className="incharge__text">{t('I give up')}</span>
                    </Button>
                  </div>
                </div>
              </>
            )}
            {redirecting && (
              <div>
                <h3 className="green">{t(sentence)}</h3>
                <h5>
                  {t('Redirecting in')} {counter} {t('seconds...')}
                </h5>
              </div>
            )}

            <small className="alert-modal__small" onClick={handleClose}>
              <span>{t('close-it')}</span>
            </small>
          </Box>
        </Modal>

        <h2 className="lead__step__title">{t('step-01')}</h2>

        <form className="form form--entry">
          <div className="form__row">
            <div className="form__field">
              <p className="form__field__title">
                {t('listing-th-lead-lastname')}*
              </p>
              <TextField
                required
                placeholder={t('listing-th-lead-lastname')}
                onChange={handleChange('name')}
                defaultValue={values.name}
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">
                {t('listing-th-lead-firstname')}*
              </p>
              <TextField
                required
                placeholder={t('listing-th-lead-firstname')}
                onChange={handleChange('surname')}
                defaultValue={values.surname}
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">{t('phone')}*</p>
              <TextField
                required
                placeholder={t('phone')}
                onChange={handleChange('phone')}
                defaultValue={values.phone}
                variant="filled"
                id="filled-basic"
              />
            </div>
          </div>

          <div className="form__row form__row--secondary">
            <div className="form__field">
              <p className="form__field__title">Email*</p>
              <TextField
                required
                placeholder="Email"
                onChange={handleChange('email')}
                defaultValue={values.email}
                autoComplete="email"
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">{t('language')}*</p>
              <Select
                required
                label={t('language')}
                onChange={handleChange('language')}
                variant="filled"
                id="filled-basic"
                defaultValue={() => {
                  if (values.language === undefined) {
                    return 'language';
                  } else {
                    return values.language;
                  }
                }}
              >
                <MenuItem disabled value="language">
                  <span required className="SelectPlaceholder">
                    {t('language')}*
                  </span>
                </MenuItem>
                <MenuItem value={'FR'}>{t('french')}</MenuItem>
                <MenuItem value={'NL'}>{t('neederlands')}</MenuItem>
                {/* 'EN', 'DE', 'IT','TR', 'ES', 'AR' */}
                <MenuItem value={'EN'}>{t('english')}</MenuItem>
                <MenuItem value={'DE'}>{t('german')}</MenuItem>
                <MenuItem value={'IT'}>{t('italian')}</MenuItem>
                <MenuItem value={'TR'}>{t('turkish')}</MenuItem>
                <MenuItem value={'ES'}>{t('spanish')}</MenuItem>
                <MenuItem value={'AR'}>{t('arabic')}</MenuItem>
              </Select>
            </div>
          </div>

          <h2 className="lead__step__title">{t('step-02')}</h2>

          <div className="form__row form">
            <div className="form__field">
              <p className="form__field__title">
                {t('listing-th-lead-lastname')}*
              </p>
              <TextField
                required
                placeholder={t('listing-th-lead-lastname')}
                onChange={handleChange('resident_name')}
                defaultValue={values.resident_name}
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">
                {t('listing-th-lead-firstname')}*
              </p>
              <TextField
                required
                placeholder={t('listing-th-lead-firstname')}
                onChange={handleChange('resident_surname')}
                defaultValue={values.resident_surname}
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">{t('age')}*</p>
              <TextField
                required
                placeholder={t('age')}
                onChange={handleChange('resident_age')}
                defaultValue={values.resident_age}
                variant="filled"
                id="filled-basic"
                type="number"
              />
            </div>
          </div>

          <div className="form__row form__row--secondary">
            <div className="form__field">
              <p className="form__field__title">{t('gender')}</p>
              <Select
                label={t('gender')}
                onChange={handleChange('resident_gender')}
                value={values.resident_gender}
                variant="filled"
                id="filled-basic"
              >
                <MenuItem value={'M'}>{t('gender-m')}</MenuItem>
                <MenuItem value={'F'}>{t('gender-f')}</MenuItem>
                <MenuItem value={'OTHER'}>{t('gender-lgbt+')}</MenuItem>
              </Select>
            </div>

            <div className="form__field">
              <p className="form__field__title">{t('referrer')}*</p>
              <Select
                required
                onChange={handleChange('resident_referer')}
                defaultValue={values.resident_referer}
                variant="filled"
                id="filled-basic"
              >
                <MenuItem value={'FAMILY'}>{t('FAMILY')}</MenuItem>
                <MenuItem value={'FRIENDS'}>{t('FRIENDS')}</MenuItem>
                <MenuItem value={'SOCIAL_ASSISTANCE'}>
                  {t('SOCIAL_ASSISTANCE')}
                </MenuItem>
                <MenuItem value={'DOCTOR'}>{t('DOCTOR')}</MenuItem>
                <MenuItem value={'OTHER'}>{t('OTHER')}</MenuItem>
              </Select>
            </div>

            <div className="form__field">
              <p className="form__field__title">Email</p>
              <TextField
                placeholder="Email"
                onChange={handleChange('resident_email')}
                defaultValue={values.resident_email}
                variant="filled"
                id="filled-basic"
              />
            </div>
          </div>

          <div className="form__row form__row--secondary">
            <div className="form__field">
              <p className="form__field__title">{t('phone')}</p>
              <TextField
                placeholder={t('phone')}
                onChange={handleChange('resident_phone')}
                defaultValue={values.resident_phone}
                variant="filled"
                id="filled-basic"
              />
            </div>
            <div className="form__field">
              <p className="form__field__title">{t('address')}</p>
              <TextField
                placeholder={t('address')}
                onChange={handleChange('resident_address')}
                defaultValue={values.resident_address}
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">{t('zipcode')}</p>
              <TextField
                placeholder={t('zipcode')}
                onChange={handleChange('resident_zipcode')}
                defaultValue={values.resident_zipcode}
                variant="filled"
                id="filled-basic"
                type="number"
              />
            </div>
          </div>
          <div className="form__row form__row--secondary">
            <div className="form__field">
              <p className="form__field__title">{t('resident-language')}</p>
              <Select
                onChange={handleChange('resident_language')}
                defaultValue={() => {
                  if (values.resident_language === undefined) {
                    return 'resident_language';
                  } else {
                    return values.resident_language;
                  }
                }}
                variant="filled"
                id="filled-basic"
              >
                <MenuItem disabled value="resident_language">
                  <span className="SelectPlaceholder">
                    {t('resident-language')}
                  </span>
                </MenuItem>
                <MenuItem value={'FR'}>{t('french')}</MenuItem>
                <MenuItem className="menuitem--last" value={'NL'}>
                  {t('neederlands')}
                </MenuItem>
                {/* 'EN', 'DE', 'IT','TR', 'ES', 'AR' */}
                <MenuItem value={'EN'}>{t('english')}</MenuItem>
                <MenuItem value={'DE'}>{t('german')}</MenuItem>
                <MenuItem value={'IT'}>{t('italian')}</MenuItem>
                <MenuItem value={'TR'}>{t('turkish')}</MenuItem>
                <MenuItem value={'ES'}>{t('spanish')}</MenuItem>
                <MenuItem value={'AR'}>{t('arabic')}</MenuItem>
              </Select>
            </div>

            <div className="form__next form__next--entry">
              <Button className="form__next" onClick={Continue}>
                {t('next-step')}
              </Button>
            </div>
          </div>
          <br />
        </form>
        <SwitchLanguage />
      </main>
    </div>
  );
};

export default LeadIncomingCaller;
