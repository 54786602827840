import React, { useEffect, useState } from 'react';
import { TextField, Button } from '@mui/material';
import Nav from '../components/Nav';
import SwitchLanguage from '../components/SwitchLanguage';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
//import { keycloak } from '../context/AuthContextProvider';
import { useAuth } from '../context/auth-context';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

const ComplaintIncomingCaller = ({ nextStep, handleChange, values }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const { state } = useAuth();
  const keycloak = state.context?.keycloak;

  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState('');
  const [openMessageText, setMessageText] = useState(
    'You can only create a question for your own residence'
  );

  const Continue = (e) => {
    e.preventDefault();

    if (
      values.name === '' ||
      values.surname === '' ||
      values.phone === '' ||
      values.email === '' ||
      values.language === '' ||
      values.complaint === '' ||
      values.resident_referer === ''
    ) {
      setOpen(true);
    } else {
      postData(e);
      async function postData(e) {
        e.preventDefault();
        const params = {
          caller_lastname: values.name,
          caller_firstname: values.surname,
          caller_lang: values.language,
          caller_email: values.email,
          caller_phone: values.phone,
          caller_referrer: values.referer,
          resident_firstname: values.resident_surname,
          resident_lastname: values.resident_name,
          resident_age: values.resident_age,
          resident_type: values.resident_type,
          residences: residenceTable.toString() || '10001',
          complaint: values.complaint,
        };

        for (const key of Object.keys(params)) {
          if (params[key] === null || params[key] === '') {
            delete params[key];
          }
        }
        await axios
          .post(
            (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
              'complaints',
            params,
            {
              headers: {
                Authorization: 'Bearer ' + keycloak.token,
              },
            }
          )
          .then((response) => {
            nextStep();
          })
          .catch(function (error) {
            setSeverity('error');
            setOpenMessage(true);
            setMessageText(
              error.response.data.errors[0]['param'] +
                ' : ' +
                error.response.data.errors[0]['msg']
            );
          });
      }
    }
  };

  // MULTISELECT

  const [residenceName, setResidenceName] = React.useState([]);
  const [residenceTable, setResidenceTable] = React.useState([]);
  const [residencesName, setResidencesName] = React.useState([]);

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];
    let residenceTable = [];

    value.forEach((item) => {
      if (duplicateRemoved.findIndex((o) => o.id === item.id) >= 0) {
        duplicateRemoved = duplicateRemoved.filter((x) => x.id === item.id);
      } else {
        duplicateRemoved.push(item);
        residenceTable.push(item.one_key);
      }
    });

    setResidenceName(duplicateRemoved);
    setResidenceTable(residenceTable);
  };

  useEffect(() => {
    async function getResidences() {
      axios
        .get(
          (process.env.REACT_APP_LEADTOOL_API_URL || 'leadtool-v3-local') +
            'residences',
          {
            headers: {
              Authorization: 'Bearer ' + keycloak.token,
            },
          }
        )
        .then((response) => {
          setResidencesName(response.data);
        });
    }
    getResidences();
  }, []);

  return (
    <div className="wrapper">
      <header>
        <Nav />
      </header>

      <main className="lead">
        <div className="lead__header">
          <h1 className="lead__header__title">{t('complaint-title')}</h1>
          <p className="lead__header__description">{t('complaint-text')}</p>
        </div>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Collapse in={open}>
            <Alert className="lead__error" severity="error">
              {t('required-fields')}
            </Alert>
          </Collapse>
          <Collapse in={openMessage}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenMessage(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {openMessageText}
            </Alert>
          </Collapse>
        </Stack>

        <h2 className="lead__step__title">{t('step-01')}</h2>

        <form className="form form--entry">
          <div className="form__row">
            <div className="form__field">
              <p className="form__field__title">
                {t('listing-th-lead-lastname')}*
              </p>
              <TextField
                required
                placeholder={t('listing-th-lead-lastname')}
                onChange={handleChange('name')}
                defaultValue={values.name}
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">
                {t('listing-th-lead-firstname')}*
              </p>
              <TextField
                required
                placeholder={t('listing-th-lead-firstname')}
                onChange={handleChange('surname')}
                defaultValue={values.surname}
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">{t('phone')}*</p>
              <TextField
                required
                placeholder="Phone"
                onChange={handleChange('phone')}
                defaultValue={values.phone}
                variant="filled"
                id="filled-basic"
              />
            </div>
          </div>

          <div className="form__row form__row--secondary">
            <div className="form__field">
              <p className="form__field__title">Email*</p>
              <TextField
                required
                placeholder="Email address"
                onChange={handleChange('email')}
                defaultValue={values.email}
                autoComplete="email"
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">{t('language')}*</p>
              <Select
                required
                onChange={handleChange('language')}
                label="Choose a language"
                defaultValue={values.language}
                variant="filled"
                id="filled-basic"
              >
                <MenuItem value={'FR'}>{t('french')}</MenuItem>
                <MenuItem className="menuitem--last" value={'NL'}>
                  {t('neederlands')}
                </MenuItem>
                {/* 'EN', 'DE', 'IT','TR', 'ES', 'AR' */}
                <MenuItem value={'EN'}>{t('english')}</MenuItem>
                <MenuItem value={'DE'}>{t('german')}</MenuItem>
                <MenuItem value={'IT'}>{t('italian')}</MenuItem>
                <MenuItem value={'TR'}>{t('turkish')}</MenuItem>
                <MenuItem value={'ES'}>{t('spanish')}</MenuItem>
                <MenuItem value={'AR'}>{t('arabic')}</MenuItem>
              </Select>
            </div>
          </div>

          <h2 className="lead__step__title">{t('step-02')}</h2>

          <div className="form__row form">
            <div className="form__field">
              <p className="form__field__title">
                {t('listing-th-lead-lastname')}
              </p>
              <TextField
                placeholder={t('listing-th-lead-lastname')}
                onChange={handleChange('resident_name')}
                defaultValue={values.resident_name}
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">
                {t('listing-th-lead-firstname')}
              </p>
              <TextField
                placeholder={t('listing-th-lead-firstname')}
                onChange={handleChange('resident_surname')}
                defaultValue={values.resident_surname}
                variant="filled"
                id="filled-basic"
              />
            </div>

            <div className="form__field">
              <p className="form__field__title">{t('referrer')}*</p>
              <Select
                required
                label="Referer"
                onChange={handleChange('referer')}
                variant="filled"
                id="filled-basic"
                defaultValue={() => {
                  if (values.referer === undefined) {
                    return 'Referer';
                  } else {
                    return values.referer;
                  }
                }}
              >
                <MenuItem disabled value="Referer">
                  <span className="SelectPlaceholder">{t('referrer')}</span>
                </MenuItem>
                <MenuItem value={'FAMILY'}>{t('FAMILY')}</MenuItem>
                <MenuItem value={'FRIENDS'}>{t('FRIENDS')}</MenuItem>
                <MenuItem value={'SOCIAL_ASSISTANCE'}>
                  {t('SOCIAL_ASSISTANCE')}
                </MenuItem>
                <MenuItem value={'DOCTOR'}>{t('DOCTOR')}</MenuItem>
                <MenuItem value={'OTHER'}>{t('OTHER')}</MenuItem>
              </Select>
            </div>
          </div>

          <div className="form__row form__row--secondary form__row--width">
            <div className="form__field form__field--large">
              <p className="form__field__title">{t('complaint')}*</p>
              <TextField
                required
                placeholder={t('complaint')}
                onChange={handleChange('complaint')}
                defaultValue={values.complaint}
                variant="filled"
                id="filled-basic"
                multiline
                rows={2}
              />
            </div>
          </div>

          <div className="form__row form__row--secondary form__row--third">
            <div className="form__field">
              <p className="form__field__title">{t('send-to-residence')}</p>
              <FormControl>
                <Select
                  multiple
                  value={residenceName}
                  onChange={handleChangeSelect}
                  renderValue={(selected) =>
                    selected.map((x) => x.name).join(', ')
                  }
                  variant="filled"
                >
                  {residencesName.map((variant) => (
                    <MenuItem key={variant.one_key} value={variant}>
                      <Checkbox
                        checked={
                          residenceName.findIndex(
                            (item) => item.one_key === variant.one_key
                          ) >= 0
                        }
                      />
                      {variant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <br />

          <div className="form__next form__next--entry">
            <Button className="form__submit" onClick={Continue}>
              {t('add-the-question')}
            </Button>
          </div>
        </form>
        <SwitchLanguage />
      </main>
    </div>
  );
};

export default ComplaintIncomingCaller;
