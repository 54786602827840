import { createMachine } from "xstate";
const actions = require("./actions");
const services = require("./services");

export const createAuthMachine = ({ url, realm, clientId }) => {
  return createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QEMCuAXAFgOgJYDtd0BiCAe3zD3wDcyBrKtLaohAugY2XVwoG0ADAF0hwxKAAOZWET74JIAB6IA7AA4ATNkEBmAKwBOAGyHdg46t3GAjABoQAT0QBaG++z7VN6+vXvNVWM-AF8Qh2YcAiJiMAAnOLI47EkAGx4AMySAW2xI1nR2WjJuXgERMUVpWTKFJGVEGwtjbHdLY00bTUF-QwAWB2cEF01u7HVVPv1ddyCtTT6wiIwcSLB8XlLIYgAZAHkAcT2AVQAVSvrquQpFFQQbPotsQ3dDTVt3wwt1QbUO7CCZi86n06mMwWMSxA+TWG1wWwgeU4vBoYGIpz2AGkAKIAOQA+tiABoABQAkgAlMm4g4XKQya51UB3VSGdTPPo2dRmdQGVT6QT2JyIYxeZ7GQSqfn6fR9OXqRbhaErPIrdabHiQJEotFKWDoTV5DLoeIACgFgkEAEpiDC1XCEdrcKi6SArrVbo09IJsJyOn1rII+i9NLpfgh+boOZotOoLDZweooXasOr4ZrEQRkMjnWiAIIAYVOZIAannTtjXe75J77g9DNhbKZ3kY4wLVOH+do5Z0JroNKZvMmVbCNSbM-hszr0Vi8YTSZTqbSRFUGR76ndRTYAf0QV45fpjLofsKEFp9NHebzOr39MOWKP0+PsHEwBlX7BMAQoAACdAMdZSAoKgOAA1UH3tMctVfd84C-fBf3-Rh8CKLgeHkMQqzXGsNzUQwGxeGwTClLROQTcMvCjfs+lFPpuUFCxVHvVZIKfaC3w-eDEIA-BYgSJIUnSdAsjiXIU0wNNHRgzjvz-HjUJKdDylEFdLmwm5cPufsWkEfRNH0Hw+m8TkBlPNlt1UQRRjomYvnBJilXEySM2wJD1mxJRJFwOJv2IClsQAMX8gBlAAJLCahw5lEF0TlG3eVQFlFaZw2CCyg3MLxwSs95mOwVIyGQCAf1QWB4h-SREgyXBUjRchKGoOhGHAnACqKkqyriCqqpqsAFNKDCKlU+lIo06KI25X0DIMcFQyaAzwy5bRuWsUVLUtLxQTytritK8rKrIarar4xJkjSTIcha-LCt2zrusO3r+qU-BMOGt11KZBp7kFKMBVDYNulFSxDEWnRBTlDQrFMIw2Tyx9HTc-APK8nyEJnHECWJckKXLMk9gJY4SQAEXLbEiYixlaxcANVE8SZgl0Rn+UmTROw0Tw3hMblLBlUMwiVfAyAgOBFEiVdRs+u4XGmX76d5Jn91Z08XGPH19KMiGiP0-pIUclVonQcXKc0-kdLeGNDBlIM6PDEYaNaTozE5IMbCMGi4dYhEjfXcbqZBOmaPl-tFfDTko300ZD1sXSvBsD3UwdFyp1zb2oq+9xLVaNlTCaSMNtSsUTElfDZS5BU7z1iCE6gidk9RVOxvTwVt3cGZqLZPQFXDc9L1GDR-BjXR44kxPn2kuDZMRhvJcQaZ2UlI8HgSkuw1PUVaaLjQ5p8foHOWKuR5r1yeOR7zv2nqn9O3DvLMsDQTEMIIKLjX15UfixQVivLXwgbywGROSP4CpQDIBgC+mkfAGGeIIN4-cQRWFBItKBR49LckeBDd2lccA-z-gA-8QCyBQAIOA8ahkfTJSmAZTQlsYG2x3r6Lo-1ZSPy5AmbaN0Or7R6rVEhX0pQXiMN4KwXxjyHn0J2TwVswSdGPAqbkcd+ZAA */
      id: "auth",

      initial: "init",
      predictableActionArguments: true,

      context: {
        counter: 0,
        tokenExpiresIn: 0,
        url: url,
        realm: realm,
        clientId: clientId,
      },

      states: {
        init: {
          invoke: {
            src: "initKeycloakClient",

            onDone: {
              target: "load user profile",
              description: `User authenticated`,
            },

            onError: "redirect to login",
          },
        },

        authenticated: {
          on: {
            LOGOUT: {
              target: "redirect to logout",
            },
          },

          states: {
            active: {
              after: {
                5000: "inactive",
              },

              on: {
                TOKEN_EXPIRING: "refreshing token",
              },
            },

            inactive: {
              on: {
                ACTIVATE: "active",
                TOKEN_EXPIRING: "tokenExpiring",
              },
            },

            "refreshing token": {
              invoke: {
                src: "refreshToken",
                onDone: "active",
                onError: "#auth.redirect to logout",
              },
            },

            tokenExpiring: {
              on: {
                REFRESH: "refreshing token",

                TOKEN_EXPIRATION_UPDATED: {
                  target: "tokenExpiring",
                  actions: "saveRemainingTime",
                  internal: true,
                },
              },

              invoke: {
                src: "checkForActualTokenExpiration",
              },

              exit: "resetRemainingTime",
              entry: "saveRemainingTime",
            },
          },

          initial: "active",

          invoke: [
            {
              src: "checkForUserMouseActivity",
            },
            {
              src: "checkForTokenExpiration",
            },
          ],
        },

        "redirect to logout": {
          type: "final",
          entry: "redirectToLogoutForm",
        },

        "redirect to login": {
          type: "final",
          entry: "redirectToLoginForm",
        },

        "load user profile": {
          invoke: {
            src: "loadUserProfile",
            onDone: {
              target: "authenticated",
              actions: "saveUserProfile",
            },
            onError: "redirect to login",
          },
        },
      },

      entry: "createKeycloakClient",
    },
    {
      actions: actions,
      services: services,
    }
  );
};
