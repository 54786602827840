import React from 'react';
import { Button } from '@mui/material';

export default function UpdateButton({ row, disabled, onUpdate }) {
  const handleClick = () => {
    onUpdate(row);
  };

  return (
    <Button disabled={disabled} onClick={handleClick}>
      Update
    </Button>
  );
}
