import { useTranslation } from 'react-i18next';

export default function OpenButton(props) {
  const { t } = useTranslation();
  if (props.open === 'OPEN' || props.open === null) {
    return (
      <button type="submit" className="handle handle--single">
        <span className="logincard__p">{t('registration-status-open')}</span>
      </button>
    );
  } else {
    return (
      <button className="report report--single">
        <span className="report__text">{t('registration-status-close')}</span>
      </button>
    );
  }
}
