import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Widget(props) {
  const { t } = useTranslation();

  return (
    <div className={`widget ${props.class} ${props.red ? 'widget--red' : ''}`}>
      <Link to={props.url} state={{ type: props.type, locked: props.locked }}>
        <div className="widget__title">
          <img className="widget__title__img" src={props.img} alt="" />
          <p className="widget__title__text">{props.title}</p>
        </div>
        <div className="widget__infos">
          <h3 className="widget__infos__num">{props.num}</h3>
          <h3 className="widget__infos__text">{props.text}</h3>
        </div>
        <span className="link">{t('See all')}</span>
      </Link>
    </div>
  );
}
