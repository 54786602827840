import { useContext } from 'react';
import { useAuth } from './context/auth-context';

import Home from './components/Home';
import LoadingSpinner from './components/LoadingSpinner';
import LogoutModal from './components/LogoutModal';

function App() {
  const { state, send } = useAuth();

  // Show the loading spinner while the user is not authenticated
  if (!state.matches('authenticated')) {
    return <LoadingSpinner />;
  }
  // If the user is authenticated display the home component
  else if (state.matches('authenticated')) {
    return (
      <>
        <Home />
        <LogoutModal />
      </>
    );
  }
}

export default App;
