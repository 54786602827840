import React from 'react';
import { Select, MenuItem } from '@mui/material';

export default function SelectCellRenderer({ value, options, onValueChange }) {
  const handleChange = (event) => {
    onValueChange(event.target.value);
  };

  return (
    <Select value={value || ''} onChange={handleChange}>
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.key} value={option.value}>
          {option.key}
        </MenuItem>
      ))}
    </Select>
  );
}
