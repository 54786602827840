import { Component } from 'react';
import ComplaintIncomingCaller from '../components/ComplaintIncomingCaller';
import Success from '../components/Success';

export default class Complaint extends Component {
  state = {
    step: 1,
    name: '',
    surname: '',
    phone: '',
    email: '',
    language: '',
    isadoctor: '',
    resident_name: '',
    resident_surname: '',
    resident_age: '',
    resident_gender: '',
    resident_language: '',
    resident_address: '',
    resident_reason: '',
    complaint: '',
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  render() {
    document.title = 'Leadtool v3 | Emeis Belgium';
    const { step } = this.state;
    const {
      name,
      surname,
      phone,
      email,
      language,
      isadoctor,
      resident_name,
      resident_surname,
      resident_age,
      resident_gender,
      resident_language,
      resident_address,
      resident_reason,
      referer,
      resident_type,
      residences,
      complaint,
    } = this.state;
    const values = {
      name,
      surname,
      phone,
      email,
      language,
      isadoctor,
      resident_name,
      resident_surname,
      resident_age,
      resident_gender,
      resident_language,
      resident_address,
      resident_reason,
      referer,
      resident_type,
      residences,
      complaint,
    };

    switch (step) {
      case 1:
        return (
          <ComplaintIncomingCaller
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 2:
        return (
          <div>
            <Success
              type="Complaint"
              description="Si vous n’avez pas encore reçu les informations de connexion, vous pouvez demander"
              sentence="New Complaint added"
            />
          </div>
        );
      default:
      // do nothing
    }
  }
}
