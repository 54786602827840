import Nav from '../components/Nav';
import SwitchLanguage from '../components/SwitchLanguage';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Entry() {
  const { t } = useTranslation();
  document.title = 'Leadtool v3 | Emeis Belgium';
  return (
    <div>
      <div className="wrapper">
        <header>
          <Nav />
        </header>

        <main className="entry">
          <h1 className="entry__title">{t('registrations-title')}</h1>

          <div className="entry__container">
            <NavLink to="/lead">
              <div className="entry__card">
                <h2 className="entry__card__title">{t('lead-title')}</h2>
                <p className="entry__card__text">{t('lead-text')}</p>
                <div className="entry__card__link"></div>
              </div>
            </NavLink>
            <NavLink to="/question">
              <div className="entry__card">
                <h2 className="entry__card__title">{t('question-title')}</h2>
                <p className="entry__card__text">{t('question-text')}</p>
                <div className="entry__card__link"></div>
              </div>
            </NavLink>
            <NavLink to="/complaint">
              <div className="entry__card">
                <h2 className="entry__card__title">{t('complaint-title')}</h2>
                <p className="entry__card__text">{t('complaint-text')}</p>
                <div className="entry__card__link"></div>
              </div>
            </NavLink>
          </div>
          <SwitchLanguage />
        </main>
      </div>

      <footer className="footer"></footer>
    </div>
  );
}
