import { Switch, BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from '../pages/Homepage';
import Listing from '../pages/Listing';
import Entry from '../pages/Entry';
import Post from '../pages/Post';
import Lead from '../pages/Lead';
import Question from '../pages/Question';
import Complaint from '../pages/Complaint';
import Urgent from '../pages/Urgent';
import Transfers from '../pages/Transfers';

const Home = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index exact path="/" element={<Homepage />} />
        <Route path="/listing" element={<Listing />} />
        <Route path="/entry" element={<Entry />} />
        <Route path="/post/:id" element={<Post />} />
        <Route path="/lead" element={<Lead />} />
        <Route path="/question" element={<Question />} />
        <Route path="/complaint" element={<Complaint />} />
        <Route path="/urgent" element={<Urgent />} />
        <Route path="/transfers" element={<Transfers />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Home;
