import { Component } from 'react';
import LeadIncomingCaller from '../components/LeadIncomingCaller';
import LeadResidence from '../components/LeadResidence';
import Success from '../components/Success';
import { useTranslation } from 'react-i18next';

export default class NewLead extends Component {
  state = {
    step: 1,
    name: '',
    surname: '',
    phone: '',
    email: '',
    language: '',
    resident_name: '',
    resident_surname: '',
    resident_age: '',
    resident_gender: '',
    resident_language: '',
    resident_address: '',
    resident_zipcode: '',
    resident_phone: '',
    resident_email: '',
    resident_budget: '',
    questions_remarks: '',
    resident_type: '',
    residences: '',
    resident_referer: '',
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
    console.log(input);
  };

  translate = () => {
    useTranslation();
  };

  render() {
    document.title = 'Leadtool v3 | Emeis Belgium';
    const { step } = this.state;
    const {
      name,
      surname,
      phone,
      email,
      language,
      isadoctor,
      resident_name,
      resident_surname,
      resident_age,
      resident_gender,
      resident_language,
      resident_address,
      resident_referer,
      resident_type,
      residences,
      resident_zipcode,
      resident_phone,
      resident_email,
      resident_budget,
      questions_remarks,
    } = this.state;
    const values = {
      name,
      surname,
      phone,
      email,
      language,
      isadoctor,
      resident_name,
      resident_surname,
      resident_age,
      resident_gender,
      resident_language,
      resident_address,
      resident_referer,
      resident_type,
      residences,
      resident_zipcode,
      resident_phone,
      resident_email,
      resident_budget,
      questions_remarks,
    };

    switch (step) {
      case 1:
        return (
          <LeadIncomingCaller
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 2:
        return (
          <LeadResidence
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            values={values}
          />
        );
      case 3:
        return (
          <div>
            <Success
              type="Lead"
              description="Si vous n’avez pas encore reçu les informations de connexion, vous pouvez demander"
              sentence="New lead added"
            />
          </div>
        );
      default:
      // do nothing
    }
  }
}
