import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { AlertTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function TransitionAlerts(props) {
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();

  if (props.severity === 'success') {
    return (
      <div className="notification-box">
        <Box sx={{ width: '100%' }}>
          <Collapse in={open}>
            <Alert severity="success" sx={{ mb: 2 }}>
              {t(props.desc)}
            </Alert>
          </Collapse>
        </Box>
      </div>
    );
  } else {
    return (
      <div className="notification-box">
        <Box sx={{ width: '100%' }}>
          <Collapse in={open}>
            <Alert
              severity={props.severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <AlertTitle>{props.num}</AlertTitle>
              {t(props.desc)} <br />
              {t(props.full)}
            </Alert>
          </Collapse>
        </Box>
      </div>
    );
  }
}
