export const checkForUserMouseActivity = (context, event) => (send) => {
  const listener = () => {
    send({ type: "ACTIVATE" });
  };

  window.addEventListener("mousemove", listener);

  return () => {
    window.removeEventListener("mousemove", listener);
  };
};

export const checkForTokenExpiration = (context, event) => (send) => {
  const interval = setInterval(() => {
    if (context?.keycloak?.token && context?.keycloak?.isTokenExpired(30)) {
      send({ type: "TOKEN_EXPIRING" });
    } else {
    }
  }, 1000);

  return () => {
    clearInterval(interval);
  };
};

export const checkForActualTokenExpiration = (context, event) => (send) => {
  const interval = setInterval(() => {
    if (context?.keycloak?.token && context?.keycloak?.isTokenExpired()) {
      send({ type: "LOGOUT" });
    } else {
      send({ type: "TOKEN_EXPIRATION_UPDATED" });
    }
  }, 1000);

  return () => {
    clearInterval(interval);
  };
};

export const initKeycloakClient = (context, event) => {
  return new Promise(async (resolve, reject) => {
    try {
      const authenticated = await context.keycloak.init({
        onLoad: "login-required",
        checkLoginIframe: false,
      });
      if (authenticated) {
        resolve();
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const loadUserProfile = (context, event) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("loading user profile");
      const profile = await context.keycloak.loadUserProfile();
      resolve(profile);
    } catch (error) {
      reject(error);
    }
  });
};

export const refreshToken = (context, event) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("refreshing token");
      const refreshed = await context.keycloak.updateToken(-1);
      if (refreshed) {
        resolve();
      } else {
        reject();
      }
    } catch (error) {
      reject(error);
    }
  });
};
