// auth-context.js

import { createContext, useContext, useEffect, useState } from 'react';
import { useMachine } from '@xstate/react';
import { createAuthMachine } from '../machines/authentication/manage-user-authentication-machine';

const AuthContext = createContext({});

const authMachine = createAuthMachine({
  url: process.env.REACT_APP_KC_URL || 'https://auth.local.orpea-belgium.com/',
  realm: process.env.REACT_APP_KC_REALM || 'leadtool-v3-local',
  clientId: process.env.REACT_APP_KC_CLIENT_ID || 'leadtool-v3-frontend',
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [state, send] = useMachine(authMachine);

  const value = {
    state: state,
    send: send,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
