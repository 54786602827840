import React, { useState, useEffect } from 'react';
import '../assets/styles/main.scss';
import { DataGrid, useGridApiRef, GridEditInputCell } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Alert, Snackbar } from '@mui/material';
import UpdateButton from '../components/UpdateButton';
import SelectCellRenderer from '../components/SelectCellRenderer';

import { useAuth } from '../context/auth-context';

export default function Transfers() {
  const { state } = useAuth();
  const keycloak = state.context?.keycloak;
  const [rows, setRows] = useState([]);
  const [residences, setResidences] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');
  const apiRef = useGridApiRef();

  useEffect(() => {
    fetchData();
    fetchResidences();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch('/api/v1/transfers', {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      });
      const data = await response.json();
      setRows(data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };
  const fetchResidences = async () => {
    try {
      const response = await fetch('/api/v1/residences?order-asc=one_key', {
        headers: {
          Authorization: 'Bearer ' + keycloak.token,
        },
      });
      const data = await response.json();
      setResidences(data);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const handleRefresh = () => {
    fetchData();
  };

  const handleUpdate = async (row) => {
    // Replace this with the actual API call to update the data
    // using a PUT request
    const response = await fetch(`/api/v1/transfers/${row.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + keycloak.token,
      },
      body: JSON.stringify({
        deceased_at: row.deceased_at,
        paliative: row.paliative,
        status: row.status,
        admission_date: row.admission_date,
        target_residence: row.target_residence,
        price_per_day: row.price_per_day,
      }),
    });

    if (response.status === 200) {
      fetchData();
      setAlertSeverity('success');
      setAlertMessage('Update successful.');
    } else if (response.status === 400) {
      const data = await response.json();
      setAlertSeverity('error');
      setAlertMessage(data.message || 'Bad Request.');
    } else {
      setAlertSeverity('error');
      setAlertMessage('An error occurred while updating the data.');
    }

    setSnackbarOpen(true);
    setTimeout(() => setSnackbarOpen(false), 10000);
  };

  const columns = [
    {
      field: 'source_one_key',
      headerName: 'Source One Key',
      resizable: true,
      headerAlign: 'left',
      align: 'left',
      cellClassName: 'transfers-table-cell',
    },
    {
      field: 'source_residence',
      headerName: 'Source Residence',
      resizable: true,
      minWidth: 150,
    },
    { field: 'type', headerName: 'Type', resizable: true },
    {
      field: 'full_name',
      headerName: 'Full Name',
      flex: 2,
      resizable: true,
      minWidth: 200,
    },
    {
      field: 'room',
      headerName: 'Room',
      resizable: true,
      editable: true,
      width: 50,
    },
    {
      field: 'deceased_at',
      headerName: 'Deceased At',
      type: 'date',
      editable: true,
      valueGetter: (params) => {
        if (params.row.deceased_at) {
          return `${
            new Date(params.row.deceased_at)
              .toLocaleString('fr-FR')
              .split(' ')[0]
          }`;
        } else {
          return null;
        }
      },
    },
    {
      field: 'paliative',
      headerName: 'Paliative',
      resizable: true,
      renderCell: (params) => (
        <SelectCellRenderer
          value={params.value}
          options={[
            { key: 'YES', value: 'YES' },
            { key: 'NO', value: 'NO' },
          ]}
          onValueChange={(newValue) => {
            params.api.updateRows([
              {
                id: params.id,
                paliative: newValue,
              },
            ]);
          }}
        />
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      resizable: true,
      minWidth: 200,
      renderCell: (params) => (
        <SelectCellRenderer
          value={params.value}
          options={[
            { key: 'STAY_HOME', value: 'STAY_HOME' },
            { key: 'ENTERED_ORPEA', value: 'ENTERED_ORPEA' },
            { key: 'ENTERED_COMPETITION', value: 'ENTERED_COMPETITION' },
            { key: 'DECEASED', value: 'DECEASED' },
            { key: 'CPAS', value: 'CPAS' },
          ]}
          onValueChange={(newValue) => {
            params.api.updateRows([
              {
                id: params.id,
                status: newValue,
              },
            ]);
          }}
        />
      ),
    },
    {
      field: 'target_residence',
      headerName: 'Target Residence',
      resizable: true,
      minWidth: 250,
      renderCell: (params) => (
        <SelectCellRenderer
          value={params.value}
          options={residences.map((residence) => ({
            key: residence.one_key + ' ' + residence.name,
            value: residence.one_key,
          }))}
          onValueChange={(newValue) => {
            params.api.updateRows([
              {
                id: params.id,
                target_residence: newValue,
              },
            ]);
          }}
        />
      ),
    },
    {
      field: 'admission_date',
      headerName: 'Admission Date',
      valueGetter: (params) => {
        if (params.row.admission_date) {
          return `${
            new Date(params.row.admission_date)
              .toLocaleString('fr-FR')
              .split(' ')[0]
          }`;
        } else {
          return null;
        }
      },
      align: 'right',
      type: 'date',
      minWidth: 120,
      editable: true,
    },
    {
      field: 'price_per_day',
      headerName: 'Price Per Day',
      editable: true,
      type: 'number',
      valueGetter: (params) => {
        if (params.row.price_per_day) {
          return `${new Intl.NumberFormat(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(params.row.price_per_day)}`;
        } else {
          return null;
        }
      },
      renderEditCell: (params) => (
        <GridEditInputCell
          {...params}
          inputProps={{
            max: 150,
            min: 0,
          }}
          onValueChange={(newValue) => {
            //newValue = Math.min(Math.max(value, 0), 150);
            params.api.updateRows([
              {
                id: params.id,
                price_per_day: newValue,
              },
            ]);
          }}
        />
      ),
    },
    {
      field: 'final_status',
      headerName: 'Final Status',
      align: 'right',
      resizable: true,
    },
    {
      field: 'update',
      headerName: 'Update',

      renderCell: (params) => {
        const isDisabled = [
          'TRANSFER',
          'RESERVATION',
          'DECEASED',
          'CPAS',
          'COMPETITION',
          'RETURN_HOME',
        ].includes(params.row.final_status);

        if (!isDisabled) {
          return <UpdateButton row={params.row} onUpdate={handleUpdate} />;
        } else {
          return <></>;
        }
      },
    },
  ];

  return (
    <div style={{ height: '90vh', width: '100%', fontSize: '0.5rem' }}>
      <Button variant="contained" onClick={handleRefresh}>
        Refresh
      </Button>
      <DataGrid
        experimentalFeatures={{ newEditingApi: true }}
        columns={columns}
        rows={rows}
        apiRef={apiRef}
        disableSelectionOnClick
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          severity={alertSeverity}
          variant="filled"
          onClose={() => setSnackbarOpen(false)}
          sx={{
            borderRadius: 1,
            bgcolor: alertSeverity === 'success' ? 'lightgreen' : 'mistyrose',
            color: alertSeverity === 'success' ? 'black' : 'white',
          }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
