import { useTranslation } from 'react-i18next';

export default function Locked() {
  const { t } = useTranslation();
  return (
    <button className="locked">
      <span className="locked__text">{t('locked')}</span>
    </button>
  );
}
